/* Breakpoints */

// ----------------------------
// Variables
// ----------------------------

$break-mobile-large:    "screen and (max-width: 767px)";
$black:					#444444;
$background-gray:		#fafafa;
$mid-gray:				#aaaaaa;
$caption-gray:			#c9c9c9;
$light-gray:			#dddddd;
$heading-gray:			#ccccb5;
$blue:					#4d77be;



/* Basic layout elements */

#writing {
	width: calc(100% * 2/3);
	height: 100vh;
	margin-left: calc(100% /3);
	overflow-y: scroll;
	background-color: $background-gray;
	-webkit-overflow-scrolling: touch;

	@media #{$break-mobile-large} {
		background: transparent;
		width: 100%;
		margin-left: 0;
	}
}

#map {
	width: calc(100% /3);
	height: 100vh;
	position: absolute;
    top: 0;
    bottom: 0;

    @media #{$break-mobile-large} {
		height: 100vh;
		width: 100%;
		display: block;
		position: fixed;
		z-index: -1;
	}
}

section,
.section {
    padding: 50px;
    line-height: 1.4;
    width: auto;
    max-width: 100%;
	transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;

	.box {
		opacity: 0.15;
		transition: all .75s ease-in-out;
		-moz-transition: all .75s ease-in-out;
		-webkit-transition: all .75s ease-in-out;
	}

	.box:nth-child(1){ -webkit-transition-delay: .15s; }
	.box:nth-child(2){ -webkit-transition-delay: .3s; }
	.box:nth-child(3){ -webkit-transition-delay: .45s; }
	.box:nth-child(4){ -webkit-transition-delay: .6s; }
	.box:nth-child(5){ -webkit-transition-delay: .75s; }
	.box:nth-child(6){ -webkit-transition-delay: .9s; }


	@media #{$break-mobile-large} {
		display: block;
		margin-bottom: 85vh;
		background: white;
		max-width: calc(100% - 40px);
	}
}

section {
	opacity: 0.25;
	border-bottom: 1px solid $light-gray;
	padding: 100px 50px;

	@media #{$break-mobile-large} {
		padding: 50px 20px;
	}
}

section.active,
section.active .box {
    opacity: 1;
}

section:last-child {
    border-bottom: none;
    margin-bottom: 200px;
}

/* This is the navigation section */

section {

	&#intro {
		padding-top: 120px;
	}

	&.navigation {
		position: fixed;
		right: 0px;
		top: 5px;
		width: auto;
		opacity: 1;
		z-index: 5;
		padding: 10px;
		border-bottom: 0px;
		background: transparent !important;

		.toggle {
			cursor: pointer;
			position: absolute;
			right: 10px;
			top: 10px;
			color: #dddddd;

			&:after {
				content: "☰";
				position: absolute;
				right: 0px;
				top: 0px;
				padding: 2px 5px;
				border-radius: 3px;
			}

			&:hover {
				color: #cbcbcb;
				text-decoration: none;
			}

			&.open {
				color: #cbcbcb;

				&:after {
					content: "✕";
				}
			}

			@media #{$break-mobile-large} {
				color: #444444;

				&:after {
					padding: 8px 10px;
					background-color: #ffffff;
					box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
				}

				&.open {
					color: #444444;
				}

			}
		}

		.menu {
			display: none;
			margin-right: 40px;
			padding: 15px 10px 10px 15px;
			background: white;
			box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3);
			border-radius: 5px;

			&.open {
				display: block;
			}

			@media #{$break-mobile-large} {
				margin-right: 50px;
			}
		}

		.nav-icon {

			&:hover {

				i {
					&::after {
						background: #b6b6a4;
					}
				}
			}
		}

	}
}

/* These are the sections with the location icons and headings */
.section.state {
	padding-top: 100px;
	padding-top: 100px;
	margin-top: -50px; /* we do this so using the nav to jump to anchors won't cut off the headings */
	margin-bottom: -120px;

	@media #{$break-mobile-large} {
		padding: 100px 20px 50px 20px;
		margin-bottom: -65px;
	}
}

.grid {
	margin-top: 4em;
	width: 100%;
	display: -ms-grid;
  	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 4px;
	grid-auto-flow: dense;

	.box {
		grid-column-start: auto;

		.image-wrapper {
			background-position: center;
			background-size: cover;
		}

		/* These are all our potential image box sizes */
		&--1x1 {
			/* Padding needs to be applied to inner divs */
			.image-wrapper {
				padding-bottom: 100%;
			}
		}
		&--1x2 {
			grid-row-end: span 2;
			.image-wrapper {
				padding-bottom: 150%;
			}
		}
		&--2x1 {
			grid-column-end: span 2;
			.image-wrapper {
				padding-bottom: calc(50% - 2px);
			}
		}
		&--2x2 {
			grid-column-end: span 2;
			grid-row-end: span 2;
			.image-wrapper {
				padding-bottom: 100%;
			}
		}
		&--3x1 {
			grid-column-end: span 3;
			.image-wrapper {
				padding-bottom: 33.33%;
			}
		}
		&--3x2 {
			grid-column-end: span 3;
			.image-wrapper {
				padding-bottom: 66.66%;
			}
		}
		&--3x3 {
			grid-column-end: span 3;
			.image-wrapper {
				padding-bottom: 100%;
			}
		}
		/* Mobile styles */
		&--1x1,
		&--1x2,
		&--2x2,
		&--3x3 {
			@media #{$break-mobile-large} {
				grid-column-end: span 3;
				.image-wrapper {
					padding-bottom: 100%;
				}
			}
		}
		&--2x1,
		&--3x1,
		&--3x2 {
			@media #{$break-mobile-large} {
				grid-column-end: span 3;
				.image-wrapper {
					padding-bottom: 66.66%;
				}
			}
		}
	}
}

/* A mess of styles for browsers that don't support grid */
.no-cssgrid .grid,
.cssgridlegacy .grid
 {
	display: block;
	margin-top: 50px;

	.box {
		width: calc(100% /3 - 6px);
		display: inline-block;
		vertical-align: top;
		border: 2px solid white;
		background-color: $background-gray;

 		.image-wrapper {
			background-size: cover;
			background-position: center;
 		}

		&--1x1 {
			width: calc(100% /3 - 6px);
			.image-wrapper {
				padding-bottom: 100%;
			}
		}
		&--1x2 {
			width: calc(100% /3 - 6px);
			.image-wrapper {
				padding-bottom: 100%;
			}
		}
		&--2x1 {
			width: calc(100% *2/3 - 8px);
			.image-wrapper {
				padding-bottom: calc(50% - 2px);
			}
		}
		&--2x2 {
			width: calc(100% /3 - 6px);
			.image-wrapper {
				padding-bottom: 100%;
			}
		}
		&--3x1 {
			width: calc(100% - 10px);
			.image-wrapper {
				padding-bottom: 33.33%;
			}
		}
		&--3x2 {
			width: calc(100% - 10px);
			.image-wrapper {
				padding-bottom: 66.66%;
			}
		}
		&--3x3 {
			width: calc(100% - 10px);
			.image-wrapper {
				padding-bottom: 100%;
			}
		}
		/* Mobile styles */
		&--1x1,
		&--1x2,
		&--2x2,
		&--3x3 {
			@media #{$break-mobile-large} {
				width: 100%;
				.image-wrapper {
					padding-bottom: 100%;
				}
			}
		}
		&--2x1,
		&--3x1,
		&--3x2 {
			@media #{$break-mobile-large} {
				width: 100%;
				.image-wrapper {
					padding-bottom: 66.66%;
				}
			}
		}
	}
}

.end {
	text-align: center;

	.h1, .h2 {
		color: $black;
		padding-bottom: 1rem;
	}

	p {
		color: $caption-gray;
		margin-bottom: 0.5rem;

		a {
			color: $caption-gray;
			text-decoration: underline;

			&:hover,
			&:active {
				color: $mid-gray;
			}
		}
	}

}

/* Type */
html,
body {
	font-family: 'PT Serif',
        -apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Roboto,
		Oxygen-Sans,
		Ubuntu,
		Cantarell,
		"Helvetica Neue",
		sans-serif;
	color: $black;
}

p {
    line-height: 1.4;
    margin-bottom: 1em;
}

em {
	font-style: italic;
}

.date {
	color: $mid-gray;
	line-height: 1.4rem;
	display: block;
}

a {
	color: $blue;
	text-decoration: none;

	&:hover,
	&:active {
		color: $blue;
		text-decoration: underline;
	}
}

/* Remove summary focus styling */
.accordion-header {
	&:hover {
		outline-color: transparent;
		outline-style: none;
	}

	&:focus {
		outline-color: transparent;
		outline-style: none;
	}
}

details.accordion {
	input:checked ~,
	&[open] {
		& .accordion-header {
			.icon {
				transform: rotate(90deg);
			}
		}

		& .accordion-body {
			max-height: 100%;
		}
	}
}

/* Icons Headings */
//
//i {
//	position: relative;
//	display: inline-block;
//	width: 3em;
//	height: 3em;
//	margin-right: 8px;
//}
//
//i+h2 {
//	display: inline-block;
//	line-height: 2.25;
//	vertical-align: top;
//}
//
//i:before {
//	font-size: 1.75em;
//	width: 1.75em;
//	text-align: center;
//	position: absolute;
//	left: 0;
//	top: 0.35em;
//	color: white;
//	z-index: 1;
//}
