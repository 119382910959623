@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,400i,700&display=swap');

@import "normalize-scss/sass/normalize";
@include normalize();

@import "layout";

@import "node_modules/spectre.css/src/variables";
@import "node_modules/spectre.css/src/mixins";

@import "node_modules/spectre.css/src/utilities";
@import "node_modules/spectre.css/src/icons";

@import "node_modules/spectre.css/src/accordions";
@import "node_modules/spectre.css/src/typography";
